import './stylesheets/home.sass'
import { definitionsFromContext } from "stimulus/webpack-helpers"
import * as $ from 'jquery'
window.$ = window.jQuery = $
require('jquery-ujs/src/rails')
import application from 'components/stimulus_app'
require('components/base')

application.load(definitionsFromContext(require.context("controllers/home/", true, /\.js$/)))
application.load(definitionsFromContext(require.context("controllers/common/", true, /(card_carousel)_controller\.js$/)))

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["grid", "expand", "expandContainer"]

  connect() {
    this.isExpandable = true;
    this.buttonClasses = ["transition-transform", "translate-y-4"];
    this.btnContainerDefaultClasses = ["absolute"];
    this.btnContainerExpandedClasses = ["sticky", "-mt-40", "transition-opacity", "duration-300", "opacity-100"]
    window.addEventListener("scroll", this.performOnScrollActions.bind(this))
  }

  performOnScrollActions() {
    if (this.isExpandable) {
      return;
    }
    if (this.isContainerScrolledIntoBottom()) {
      this.disableShowMoreContainer();
    } else {
      this.enableShowMoreContainer();
    }
  }

  isContainerScrolledIntoBottom() {
    let rect = this.gridTarget.getBoundingClientRect();
    let offset = 170;
    return (rect.top > window.innerHeight - offset)
  }

  disableShowMoreContainer() {
    this.expandTarget.parentElement.classList.remove("opacity-100")
    this.expandTarget.parentElement.classList.add("opacity-0")
    this.expandTarget.classList.remove("pointer-events-auto")
  }
  
  enableShowMoreContainer() {
    this.expandTarget.parentElement.classList.add("opacity-100")
    this.expandTarget.parentElement.classList.remove("opacity-0")
    this.expandTarget.classList.add("pointer-events-auto")
  }

  onExpand() {
    if (this.isExpandable) {
      this.isExpandable = false;
      this.expandTarget.innerText = "Show less";
      this.gridTarget.style.maxHeight = "max-content";
      this.gridTarget.classList.remove("overflow-hidden")
      this.expandContainerTarget.classList.add(...this.btnContainerExpandedClasses)
      this.expandContainerTarget.classList.remove(...this.btnContainerDefaultClasses)
      this.expandTarget.classList.add(...this.buttonClasses)
    } else {
      this.isExpandable = true;
      this.expandTarget.innerText = "Show more";
      this.gridTarget.style.maxHeight = "33rem";
      this.gridTarget.scrollIntoView()
      this.gridTarget.classList.add("overflow-hidden")
      this.expandContainerTarget.classList.remove(...this.btnContainerExpandedClasses)
      this.expandContainerTarget.classList.add(...this.btnContainerDefaultClasses)
      this.expandTarget.classList.remove(...this.buttonClasses)
    }
  }
}

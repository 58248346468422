import { Controller } from "stimulus"

export default class extends Controller {
  logHeroBlockSearchEvent(event) {
    postGADataLayerEvents(event, this._getBlockName(event.currentTarget), document.querySelector('#hero_block_search_terms').value)
  }

  logThumbnailLinkClick(event) {
    const currentTarget = event.currentTarget
    postGADataLayerEvents(event, this._getBlockName(currentTarget), this._getDataAttrValue(currentTarget, 'altGaText'))
  }

  logAnchorLinkClick(event) {
    postGADataLayerEvents(event, this._getBlockName(event.currentTarget))
  }

  _getBlockName(element) {
    return this._getDataAttrValue(element.closest('[data-block-name]'), 'blockName')
  }

  _getDataAttrValue(element, attrName) {
    return element.dataset[attrName]
  }
}